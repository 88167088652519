<template>
  <div id="internationalSwift">
    <div class="content_box">
      <div class="inner">
        <div class="content_title flex_between">
          <div>
            <h2>{{ $t('deposit.default.deposit') }}</h2>
            <p></p>
          </div>
          <div class="width_img">
            <img src="@/assets/images/channel/wise.png" alt />
          </div>
        </div>
        <div class="form_internationalAndAu" v-if="formFlag">
          <strong>{{ $t('deposit.wiseP2p.header') }}</strong>
          <el-form :model="wiseForm" ref="wiseForm" :rules="internationalRules" class="wise_form">
            <div class="box">
              <strong class="title">
                <span>1</span>
                {{ $t('deposit.wiseP2p.desc') }}
              </strong>
              <div class="form_content_box">
                <div class="info">
                  <p class="text_2rem">1、{{ $t('deposit.wiseP2p.inst1') }}</p>
                  <p class="text_2rem">2、{{ $t('deposit.wiseP2p.inst2') }}</p>
                  <p class="text_2rem">3、{{ $t('deposit.wiseP2p.inst3') }}</p>
                  <p class="text_2rem">4、{{ $t('deposit.wiseP2p.inst4') }}</p>
                  <p class="text_2rem">{{ $t(`deposit.wiseP2p.inst4_1`) }}</p>
                  <p class="text_2rem">{{ $t(`deposit.wiseP2p.inst4_2`) }}</p>
                </div>
              </div>
            </div>
            <div class="box">
              <strong class="title">
                <span>2</span>
                {{ $t('deposit.wiseP2p.sendReceipt') }}
              </strong>
              <div class="form_content_box">
                <div class="info">
                  <p class="text_2rem">{{ $t('deposit.wiseP2p.agent') }}</p>
                  <p class="text_2rem">{{ $t('deposit.wiseP2p.email') }}</p>
                </div>

                <ul class="clearfix">
                  <li>
                    <AccountNumber
                      :supportedCurrenciesList="['USD', 'EUR', 'GBP']"
                      @setAccountNumber="setAccountNumber"
                      @setCurrency="setCurrency"
                    ></AccountNumber>
                    <span>{{ $t('common.field.supportedAccountCurrencies') }}: USD、EUR、GBP</span>
                  </li>
                  <li>
                    <numeric-input
                      v-model="wiseForm.amount"
                      :precision="2"
                      :label="$t('common.field.amt')"
                      name="amount"
                    ></numeric-input>
                  </li>
                </ul>
                <ul class="clearfix">
                  <li>
                    <el-form-item prop="uploadFile" :label="$t('common.field.upload')" class="upload_label">
                      <vUpload
                        :limit="6"
                        :selfText="true"
                        v-on:updateFileInfo="updateFileInfo"
                        data-testid="updateFileInfo"
                      ></vUpload>
                    </el-form-item>
                  </li>
                  <li>
                    <InputForm
                      :label="$t('common.field.notes')"
                      name="applicationNotes"
                      v-model="wiseForm.notes"
                    ></InputForm>
                  </li>
                </ul>

                <div class="form_button">
                  <el-button
                    class="purple_button"
                    :loading="loading"
                    :disabled="loading"
                    @click="submitForm()"
                    data-testid="submit"
                  >
                    {{ $t('common.button.submit') }}
                  </el-button>
                </div>
              </div>
            </div>
          </el-form>
        </div>
        <Result v-if="!formFlag">{{ $t('deposit.default.successMsg') }}</Result>
      </div>
    </div>
  </div>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import AccountNumber from '@/components/form/AccountNumber';
import vUpload from '@/components/vUpload';
import Result from '@/components/Result';
import mixin from '@/mixins/page/deposit';
import { apiwisep2p_payment } from '@/resource';

export default {
  name: 'internationalSwift',
  components: { NumericInput, AccountNumber, vUpload, Result },
  mixins: [mixin],
  data() {
    const checkUploadFile = (rule, value, callback) => {
      if (!(this.fileList.length > 0)) callback(new Error(this.$t('common.formValidation.fileReceipt')));
      else callback();
    };
    const validateAmount = (rule, value, callback) => {
      if (value === '' || !Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')));
      } else if (parseFloat(value) < this.minLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLarger_currency_type', {
              currencyType: this.$options.filters.currencyToSymbol(this.accountCurrency),
              minLimit: this.minLimit
            })
          )
        );
      } else if (parseFloat(value) > this.maxlimitAcc) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLess_currency_type', {
              currencyType: this.$options.filters.currencyToSymbol(this.accountCurrency),
              maxLimit: this.maxlimitAcc,
              currency: this.accountCurrency
            })
          )
        );
      } else {
        callback();
      }
    };
    return {
      maxlimitAcc: 5000,
      wiseForm: {
        account: '',
        accountNumber: '',
        amount: '',
        notes: ''
      },
      internationalRules: {
        accountNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: 'change'
          }
        ],
        amount: [
          {
            required: true,
            validator: validateAmount,
            trigger: 'blur'
          }
        ],
        uploadFile: [
          {
            validator: checkUploadFile,
            required: true,
            trigger: 'change'
          }
        ]
      },
      fileList: [],
      isShow: false,
      formFlag: true
    };
  },
  computed: {
    countryCode() {
      return this.$store.state.common.countryCode;
    },
    bank_infos() {
      return this.$config.bank_infos(this.accountCurrency, this.regulator);
    }
  },
  watch: {
    accountCurrency() {
      if (this.wiseForm.amount !== 0) this.$refs.wiseForm.validateField('amount');
    }
  },
  methods: {
    setAccountNumber(accountNumber) {
      this.wiseForm.accountNumber = accountNumber;
    },
    updateFileInfo(fileInfo) {
      this.fileList = fileInfo.fileList;
      this.$refs['wiseForm'].validateField('uploadFile');
    },
    submitForm() {
      this.$refs['wiseForm'].validate(valid => {
        if (valid) {
          this.loading = true;
          this.submitDeposit()
            .then(result => {
              if (result.data.data) {
                this.formFlag = false;
              } else {
                this.errorMessage(this.$t('deposit.default.failed'));
                // re-calling anti-reuse token
                this.fetchToken()
                  .then(resp => {
                    this.loading = false;
                  })
                  .catch(resp => {
                    this.loading = false;
                    this.errorMessage(this.$t('resetPassword.failed'));
                  });
              }
            })
            .catch(err => {
              this.loading = false;
              this.errorMessage(this.$t('deposit.default.failed'));
            });
        } else {
          return false;
        }
      });
    },
    submitDeposit() {
      return apiwisep2p_payment(
        {
          mt4Account: this.wiseForm.accountNumber,
          operateAmount: this.wiseForm.amount,
          applicationNotes: this.wiseForm.notes,
          // currency: this.accountCurrency,
          fileList: this.fileList
        },
        this.token
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/internationalAndAu.scss';
.upload_label {
  /deep/ .el-form-item__label {
    top: -0px !important;
  }
  /deep/ .el-form-item__content {
    margin-top: 13px;
  }
}
.wise_form {
  /deep/ .el-form-item {
    margin-bottom: 10px;
  }
  .clearfix {
    margin-bottom: 40px;
  }
}
.flex_between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto !important;
  .width_img {
    width: 120px;
    height: 120px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.text_2rem {
  text-indent: 2rem;
}
</style>
